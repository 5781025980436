/**
 * Module: Icon
 * @author: Konstantinos Kataras
 *
 */

$icon-sizes: (
  xxs: 0.125em,
  xs: 0.25em,
  sm: 0.5em,
  av: 0.8em,
  md: 1em,
  lg: 1.8em,
  lg2: 2.2em,
  xl: 3em,
  xxl: 4em

) !default;

.icon {
  font-size: 1em;

  @each $key, $size in $icon-sizes {
    &--#{$key} {
      font-size: $size;
    }
  }

  &:after {
    @extend .position;
    left: 50%;
    top: 50%;
    transition: transform $animation-timing $animation-default-bezier-curve;
    transform: translate(-50%, 200%);
  }

  &--hover-effect {
    &:hover {
      opacity: 0.7;
    }
  }

  &--inactive {
    pointer-events: none;
  }

  &--circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    min-width: 32px;
  }

  &--circle-lg {
    width: 48px;
    height: 48px;
    border-color: $color-gray-dark;
    font-size: 36px;
    min-width:48px;
  }

  &--circle-hover {
    overflow: hidden;
    position: relative;

    &:before {
      transition: transform $animation-timing $animation-default-bezier-curve;
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
      top: 50%;
    }

    .icon__hover-scale-animation:hover & {
      &:before {
        transform: translate(-50%, -200%);
      }

      &:after {
        transform: translate(-50%, -50%);
      }
    }
  }
}
