/**
 * Utility: Media
 * @author: Konstantinos Kataras
 *
 */

.media {
  display: block;

  &--center {
    margin-left: auto;
    margin-right: auto;
  }

  &--clear-play-button {
    video {
      &::-webkit-media-controls-panel {
        display: none!important;
        -webkit-appearance: none;
      }

      &::--webkit-media-controls-play-button {
        display: none!important;
        -webkit-appearance: none;
      }

      &::-webkit-media-controls-start-playback-button {
        display: none!important;
        -webkit-appearance: none;
      }
    }
  }

  &--video-container {
    position: relative;
    padding-bottom: 0;
    padding-top: 0;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed,
    .map {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &--maximum-width {
    max-width: 100%;
    width: auto;
  }

  &--full-width {
    max-width: 100%;
    width: 100%;
  }

  &--max-800px {
    width: 100%;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
  }
}
