.overlayLayer {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -1;
  transition: opacity $animation-timing $animation-default-bezier-curve;
  opacity: 0;

  &--open {
    opacity: 1;
  }

  &--transitionEnd {
    z-index: $zIndex-overlay;
  }
}
