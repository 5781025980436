// Navigation

.navigation {
  &--main {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;

    @include respond-up-to(md) {
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      transform: translateY(-100%);
      opacity: 0;
      z-index: 0;
      background-color: $color-gray-darker;
      overflow-y: auto;
      max-height: 100vh;

      &.open {
        transform: translateY(0);
        opacity: 1;
      }

      height: calc(100vh - #{$header-height-sm});

      @include respond-to(sm) {
        height: calc(100vh - #{$header-height-sm-up});
      }

      .admin-bar & {
        height: calc(100vh - #{$header-height-sm} - 46px);

        @include respond-to(sm) {
          height: calc(100vh - #{$header-height-sm-up} - 46px);
        }
      }
    }

    @include respond-to(md) {
      position: static;
      display: flex;
      justify-content: flex-end;
      width: calc(100% - 375px);
    }
  }
}

.navigation__info {
  padding: $container-padding;
}
