/**
 * Component: Header
 *
 */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;

  box-shadow: 0 1px 3px 0 rgba(24, 24, 26, 0.15);

  .admin-bar & {
    top: 46px;

    @include respond-to-custom(783px) {
      top: 32px;
    }
  }
}

.header__container {
  @extend .flex;
  @extend .flex--split;
}

.header__background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  z-index: 1;
}

.header__logo {
  margin: 18px 0;
  z-index: 2;
  max-width: 100% !important;

  @include respond-to(sm) {
    margin: 20px 0;
  }

  svg {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.header__menuButton {
  z-index: 2;

  @extend .hide--md-up;
  @extend .flex;
  @extend .flex--center;
}

.header__link {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0;
  padding-left: 10px;
  position: relative;

  @include respond-to(lg) {
    margin-left: 8px;
    padding-left: 26px;
  }

  @include respond-up-to(md) {
    position: fixed;
    z-index: 999;
    top: 150px;
    background-color: #fff;
    right: 0;
    transform: rotate(-90deg) translate(0, -100%);
    transform-origin: 100% 0;
    padding: 10px 20px;
    border: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }

  @include respond-to-range(1024px, 1100px) {
    font-size: 1em;
  }

  &:before {
    content: '';
    position: absolute;
    height: 32px;
    display: block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-left: 2px solid $color-gray-light;

    @include respond-up-to(md) {
      display: none;
    }
  }

  @extend .type--500;
}
