.heading-transition__spacer {
  display: block;
  visibility: hidden;
}

.heading-transition__content {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  overflow: hidden;

  transition: opacity 0.8s $animation-default-bezier-curve, height 1.2s $animation-default-bezier-curve;

  @include trigger('.start-animate') {
    height: 100%;
  }
}
