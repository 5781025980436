/**
 *  Utility Cover - Plays along with cover js module.
 *  @author: Konstantinos Kataras
 */

.cover {
  @extend .position;
  @extend .position--center;
  opacity: 0;
  transition: opacity $animation-timing $animation-default-bezier-curve;

  &.cover__element--narrow {
    height: auto;
    width: 100%;
  }

  &.cover__element--wide {
    height: 100%;
    width: auto;
  }

  &--measured {
    opacity: 1;
  }

  &--portrait-fit {
    @include portrait() {
      width: 100%!important;
      height: auto!important;
    }
  }
}
