/**
 * Generic: Resets
 * Resets basic object values
 *
 */

body,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
dl, dd, ol, ul,
form, fieldset, legend,
figure,
table, th, td, caption,
hr {
  margin: 0;
  padding: 0;
}

/**
 * Give a help cursor to elements that give extra info on `:hover`.
 */
abbr[title],
dfn[title] {
  cursor: help;
}

/**
 * Remove underlines from potentially troublesome elements.
 */
u,
ins {
  text-decoration: none;
}

/**
 * Apply faux underlines to inserted text via `border-bottom`.
 */
ins {
  border-bottom: 1px solid;
}

.row {
  &:before {
    width: 100%;
  }
}
