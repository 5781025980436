/**
 * Utility: Margin
 * @author: Konstantinos Kataras
 *
 */

.margin-bottom {
  &--mobile-sm {
    @include respond-up-to(sm) {
      margin-bottom: $separator-sm !important;
    }
  }

  &--mobile-tablet-sm {
    @include respond-up-to(md) {
      margin-bottom: $separator-sm;
    }
  }

  &--tablet-up-xs {
    @include respond-to(md) {
      margin-bottom: $separator-xs;
    }
  }

  &--tablet-up {
    @include respond-to(sm) {
      margin-bottom: 50px;
    }
  }

  &--mobile-up-xxs {
    @include respond-to(sm) {
      margin-bottom: $separator-xxs;
    }
  }

  &--off-md {
    @include respond-to-range($breakpoint-sm-min, $breakpoint-sm-max) {
      margin-bottom: 0!important;
    }
  }

  &--content {
    margin-bottom: $separator-xs;

    @include respond-to(md) {
      margin-bottom: $separator-md;
    }
  }

  &--content-wide {
    margin-bottom: $separator-sm;

    @include respond-to(sm) {
      margin-bottom: $separator-md;
    }

    @include respond-to(md) {
      margin-bottom: $separator-md + $separator-xxxs;
    }
  }

  &--content-ultra-wide {
    margin-bottom: $separator-sm;

    @include respond-to(sm) {
      margin-bottom: $separator-md;
    }

    @include respond-to(md) {
      margin-bottom: $separator-lg + $separator-xxs;
    }
  }

  &--content-tight {
    margin-bottom: $separator-xxs;

    @include respond-to(md) {
      margin-bottom: $separator-sm;
    }
  }

  &--content-tighter {
    margin-bottom: $separator-xxs;

    @include respond-to(md) {
      margin-bottom: $separator-xs;
    }
  }

  &--content-tightest {
    margin-bottom: $separator-xxxs;

    @include respond-to(md) {
      margin-bottom: $separator-xxs;
    }
  }

  &--grid-space {
    margin-bottom: $grid-gutter-width;
  }

  &--long {
    margin-bottom: $separator-md + $separator-xs;

    @include respond-to(md) {
      margin-bottom: $separator-lg + $separator-md;
    }
  }

  &--disproportional {
    margin-bottom: $separator-xxs;

    @include respond-to(lg) {
      margin-bottom: $separator-md;
    }
  }

  &--wide-sm-lg-up {
    @include respond-to(lg) {
      margin-bottom: $separator-xs;
    }
  }

  &--wide-small-lg-up {
    @include respond-to(lg) {
      margin-bottom: $separator-sm;
    }
  }
}

.margin-top {
  &--content-wide {
    margin-top: $separator-sm;

    @include respond-to(sm) {
      margin-top: $separator-md;
    }

    @include respond-to(md) {
      margin-top: $separator-md + $separator-xxxs;
    }
  }

  &--content-wide-tight {
    margin-top: $separator-xxxs;

    @include respond-to(sm) {
      margin-top: $separator-xxs;
    }

    @include respond-to(md) {
      margin-top: $separator-xs;
    }
  }
}

.margin-paragraph {
  @include respond-to(sm) {
    margin-bottom: $separator-xxs;
  }
}
