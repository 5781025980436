.modalSlider {
  width: 100%;
  position: relative;

  &--top {
    margin-top: 64px;
    height: calc(90% - 64px);
    padding: 2% 0;

    .modalSlider__slide {
      width: 100vw;
      height: 100%;
      position: relative;
    }

    .swiper-wrapper {
      height: 100%;
    }
  }

  &--bottom {
    height: 10%;
    background-color: #323233;
    padding: 8px 0;

    .modalSlider__container {
      width: calc(50vw - 120px);
      height: 100%;
      position: relative;
      .swiper-container {
        height: 100%;
      }
    }

    .modalSlider__sides {
      width: 25vw;
    }

    .swiper-slide {
      width: auto;
    }
  }

  .swiper-slide:not(.swiper-slide-active) {
    opacity: 0.25;
  }

  .swiper-button-prev {
    right: 100%;
    left: auto;
    display: block;
    min-width: 60px;
  }

  .swiper-button-next {
    left: 100%;
    right: auto;
    display: block;
    min-width: 60px;
  }
}

.modalSlider__thumbnail {
  height: 100%;
  width: auto;
}
