/**
 * Module: Button
 *
 */

.button {
  outline: none;
  box-shadow: none;
  align-items: stretch;
  cursor: pointer;
  justify-content: center;
  transition: background-color $animation-timing ease-out;
  display: flex;
  flex-wrap: nowrap;
  color: $color-gray-dark;
  line-height: 0;
  border-radius: 100px;
  border: 2px solid transparent;

  &.flex--right {
    justify-content: flex-start;
  }

  .icon {
    margin-right: 10px;
    border-color: $color-yellow;
    color: $color-yellow;
    transition: color $animation-timing ease-out, border-color $animation-timing ease-out;
  }

  &--circle {
    border-radius: 50%;
    border: 2px solid $color-yellow;
    width: 32px;
    height: 32px;
    min-width: 32px;
    line-height: 1.78;
    color: $color-yellow;
  }

  &--no-text {
    .icon {
      margin-right: 0;
    }
  }

  &:hover,
  .button__hover:hover & {
    color: $color-yellow;
  }

  &.button--dark {
    color: $color-white;
  }

  &.button--yellow {
    color: $color-white;

    .icon {
      color: $color-white;
      border-color: $color-white;
    }
  }

  &--primary {
    padding: 10px 38px;

    .icon {
      color: $color-yellow;
      border-color: $color-yellow;
    }

    &:hover,
    .button__hover:hover & {
      color: $color-white;
      background-color: $color-yellow;

      .icon {
        border-color: $color-yellow;
        color: $color-white;
      }
    }

    &.button--dark {
      color: $color-white;
    }

    &.button--yellow {
      color: $color-white;

      .icon {
        color: $color-white;
        border-color: $color-white;
      }

      &:hover,
      .button__hover:hover & {
        color: $color-gray-darker;
        background-color: $color-white;

        .icon {
          color: $color-gray-darker;
          border-color: $color-white;
        }
      }
    }
  }

  &--secondary {
    color: $color-white;
    background-color: $color-yellow;
    padding: 10px 40px !important;
    border: 2px solid transparent;

    @extend .type--button;

    &.button--yellow {
      border: 2px solid $color-white;

      &:hover,
      .button__hover:hover & {
        border: 2px solid $color-white !important;
        background-color: $color-white !important;
        color: $color-yellow !important;
      }
    }

    &:hover,
    .button__hover:hover & {
      color: $color-yellow;
      background-color: transparent;
      border: 2px solid $color-yellow;
    }
  }

  &--pause {
    .icon {
      @extend .icon--pause;
    }
  }

  &.button--playing {
    visibility: hidden;

    @extend .button--pause;

    .button__play-parent:hover & {
      visibility: visible;
    }
  }

  &--circular {
    display: flex;
    @extend .flex--center;

    z-index: 10;

    &:before {
      content: '';
      width: 72px;
      height: 72px;
      border-radius: 50%;
      display: block;
      top: 0;
      left: 0;
      background-color: $color-white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
      transition: box-shadow $animation-timing $animation-default-bezier-curve, transform $animation-timing $animation-default-bezier-curve, background-color $animation-timing $animation-default-bezier-curve;
    }

    &.button--transparent {
      &:before {
        background-color: rgba(0, 0, 0, 0.6);
      }

      &:hover {
        &:before {
          background-color: $color-red;
        }

        .icon {
          color: $color-white;
        }
      }
    }

    .icon {
      margin-right: 0;
      position: absolute;
      color: $color-gray-darker;
      font-size: 1.8em;
      transition: font-size $animation-timing $animation-default-bezier-curve, color $animation-timing $animation-default-bezier-curve;

      @extend .position--center;
    }

    &:hover {
      &:before {
        transform: scale(1.2);
        background-color: $color-yellow;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      }

      .icon {
        font-size: 2.6em;
        color: $color-white;
      }
    }

    &.button--playing {
      visibility: hidden;

      @extend .button--pause;

      .button__play-parent:hover & {
        visibility: visible;
      }
    }
  }

  &--play {
    width: 96px;
    height: 96px;
    padding: 0;
    border-radius: 50%;
    background-color: $color-white;
    color: $color-gray-darker !important;
    font-size: 2.5em !important;
    z-index: 10;

    @extend .icon;
    @extend .icon--play;
  }

  &--hide-text-md-down,
  .button__hide-text-md-down & {
    @include respond-up-to(md) {
      .button__text {
        display: none;
      }

      .icon {
        margin-right: 0;
      }
    }
  }

  &--hide-text-sm-down,
  .button__hide-text-sm-down & {
    @include respond-up-to(sm) {
      .button__text {
        display: none;
      }

      .icon {
        margin-right: 0;
      }
    }
  }
}

.btn {
  @extend .button;
  @extend .button--secondary;
  @extend .type--button;
  display: inline-block;
  margin: 5px 0;
  line-height: 1.7 !important;
  color: $color-white !important;
  font-weight: 500;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out !important;

  &:hover,
  &:focus {
    background-color: $color-white;
    color: $color-yellow !important;
  }
}

.button__text {
  transition: color 0.2s linear;
  display: flex;
  align-content: center;
  white-space: normal;
  text-align: left;

  @extend .type;
  @extend .type--button;

  &--non_breakable {
    white-space: nowrap;
  }
}

.button__container {
  display: inline-block;

  &--sm-full {
    @include respond-up-to(sm) {
      width: 100%;
    }
  }
}

.button__group {
  @include respond-up-to(sm) {
    .button-container {
      display: block;
    }
  }

  &--centered {
    text-align: center;
  }

  &--spaced {
    .button__container {
      margin: 0 3px 10px;
    }
  }
}

input.button {
  padding: 20px 40px;
}
