.list {
  &--straight {
    @extend .flex;
    @extend .flex--center;
    @extend .flex--wrap
  }

  &--dashed {
    > li {
      padding-left: 30px;
      position: relative;

      &:before {
        content: '-';
        margin-right: 2rem;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  &--spaced-xs {
    > li {
      padding: $separator-xs 0;
    }
  }

  &--separated {
    > li {
      border-bottom: 1px solid $color-gray-open;

      &:first-child {
        border-top: 1px solid $color-gray-open;
      }
    }
  }

  &--enumerated {
    > li {
      position: relative;
      padding: 6px 0 $separator-sm $separator-lg;

      @include respond-to(md) {
        padding: 6px $separator-sm $separator-md $separator-lg + $separator-md + $separator-xxs;
      }

      &:before {
        @extend .flex;
        @extend .flex--center;
        @extend .transition-fade-in-up;
        transition-delay: inherit;
        content: attr(data-enumeration);
        width: 32px;
        height: 32px;
        border: 2px solid $color-yellow;
        border-radius: 50%;
        color: $color-yellow;
        position: absolute;
        left: 0;
        top: 0;
      }

      &:not(:last-child) {
        &:after {
          transition: max-height 0.8s $animation-default-bezier-curve, opacity $animation-timing $animation-default-bezier-curve, transform 0.8s $animation-default-bezier-curve;
          transition-delay: inherit;
          opacity: 0;
          max-height: 0;
          content: '';
          display: block;
          position: absolute;
          height: calc(100% - 32px);
          width: 2px;
          background-color: $color-gray-light;
          left: 15px;
          top: 32px;
          transform: translateY(80px);

          .start-animate & {
            max-height: calc(100% - 32px);
            opacity: 1;
            transform: translateY(0);
          }
        }
      }
    }
  }
}

.list__item {
  .list--capitalized & {
    @extend .type--caps;
  }

  .list--sm-padded & {
    padding-right: $separator-xxs + $separator-xxxs;
    padding-left: $separator-xxs + $separator-xxxs;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
