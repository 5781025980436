$scale-modal-zoom: 1.15;
$scale-modal-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.25);

.scaleModal {
  transition: transform $animation-timing $animation-default-bezier-curve;

  @include trigger('.scaleModal__trigger') {
    transform: scale($scale-modal-zoom);
    box-shadow: $scale-modal-shadow;
    z-index: $zIndex-overlay + 1;
    cursor: default;

    .scaleModal__scaleElement {
      transform: scale($scale-modal-zoom);
      transition: transform 0s $animation-default-bezier-curve;
    }
  }

  @include trigger('.scaleModal__afterTransition') {
    position: absolute;
    width: 100%;
    z-index: 9;

    .scaleModal__stop-scale {
      transform: scale(1)!important;
    }
  }

  .scaleModal__trigger.scaleModal__afterTransition &,
  &.scaleModal__trigger.scaleModal__afterTransition {
    z-index: $zIndex-overlay + 2;
  }

  cursor: pointer;
}

.scaleModal__scaleElement {
  transition: transform $animation-timing $animation-default-bezier-curve;
}

.scaleModal__hide {
  .scaleModal__trigger & {
    display: none;
  }
}

.scaleModal__text {
  padding: 0 5%;

  .scaleModal__trigger & {
    text-align: left;
  }
}

.scaleModal__slidingPart {
  @include respond-to(sm) {
    opacity: 1!important;
  }
}

.scaleModal__close {
  @extend .flex;
  @extend .flex--center;
  @extend .space--outer-top-md;
  @extend .type--white;
  @extend .type--button;
  position: relative;
  width: 100%;
  height: 62px;
  cursor: pointer;

  span {
    position: relative;
  }

  &:before {
    @extend .section--yellow;
    content: '';
    position: absolute;
    display: block;
    left: -12px;
    top: 0;
    width: calc(100% + 24px);
    height: 100%;
  }
  &:after {
    content: '';
    position: absolute;
  }
}
