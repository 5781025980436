/**
 * Module: Modal
 *
 */

.modal {
  width: 100%;
  height: 100%;
  position: fixed!important;
  background-color: $modal-underlay-background-color;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s $animation-default-bezier-curve;
  color: white;

  &--open {
    opacity: 1;
    visibility: visible;
  }

  &--under-header {
    z-index: 29;
    top: 60px;

    @include respond-to(sm) {
      top: 100px;
    }

    .admin-bar & {
      top: 106px;

      @include respond-to(sm) {
        top: 100px + 46px
      }

      @include respond-to-custom(783px) {
        top: 100px + 32px;
      }
    }
  }

  &--no-mobile {
    display: none;

    @include respond-to(md) {
      display: block;
    }
  }
}

.modal__error {
  display: none;
}

.modal__close {
  z-index: 100;
}

.modal__body {
  overflow: hidden;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal__container {
  height: 100vh;
  width: 100%;
  margin: auto;
  box-shadow: 0 0 120px 0 rgba(0, 0, 0, 0.5);
  background-color: $color-black;
  transition: transform $animation-timing $animation-default-bezier-curve;
  transform: scaleY(0);
  position: relative;
  overflow: hidden;

  .modal--loaded & {
    transform: scaleY(1);
  }
}

.modal__full-height_container {
  @extend .container;
  max-height: 60vh;

  @include landscape {
    max-height: 60vh;
  }
}

.modal__confirmation {
  display: none;
}

.modal__overflow {
  position: relative;

  @include landscape {
    max-height: calc(40vh - 10vw);
  }

  &:after {
    content: '';
    width: 100%;
    height: 20px;
    display: block;
    position: absolute;
    background-color: white;
    bottom: -19px;
    left: 0;
  }
}

.modal__overflowInner {
  overflow-y: scroll;
  height: 100%;
  max-height: calc(40vh - 10vw);
  @extend .space--inner-top-xs;
  @extend .space--inner-bottom-xs;
}

.modal__column {
  @extend .col-xs-8;
  @extend .col-xs-offset-2;
  @extend .col-md-6;
  @extend .col-md-offset-3;

  @include respond-to(lg) {
    float: none;
    width: auto;
    max-width: 980px;
    padding: 0;
    margin: auto;
  }

  @include landscape {
    @include respond-up-to(md) {
      width: 50%;
      margin-left: 25%;
    }
  }
}

[data-scale-modal="modal"],
[data-scale-modal="action"]
{
  cursor: pointer;
}
