/*
* Fade in out animations
*
*/

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin fade-in {
  animation-name: fade-in;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: both;
}

.fade-in {
  @include fade-in;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@mixin fade-out {
  animation-name: fade-out;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: both;
}

.fade-out {
  @include fade-out;
}
