/**
 * Utility: Flex
 * @author: Konstantinos Kataras
 *
 */

.flex {
  display: flex !important;

  &--same-height {
    flex-wrap: nowrap;
    justify-content: center;
  }

  &--center {
    align-items: center;
    justify-content: space-around;
  }

  &--reference {
    position: relative;
  }

  &--stretch {
    align-items: stretch;
  }

  &--spread {
    justify-content: space-around;
  }

  &--vertical-center {
    align-items: center;
  }

  &--horizontal-center {
    justify-content: center;
  }

  &--column {
    flex-direction: column;
  }

  &--column-left-md-up {
    @include respond-to(md) {
      align-items: flex-start;
    }
  }

  &--column-left-sm-up {
    @include respond-to(sm) {
      align-items: flex-start;
    }
  }

  &--left-aligned {
    align-items: flex-start;
  }

  &--split {
    justify-content: space-between;
  }

  &--split-sm-down {
    @include respond-up-to(sm) {
      justify-content: space-between;
    }
  }

  &--down-right {
    align-items: flex-end;
    justify-content: flex-start;
  }

  &--up-right {
    align-items: flex-start;
    justify-content: flex-end;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--no-wrap {
    flex-wrap: nowrap;
  }

  &--right {
    justify-content: flex-start;
  }

  &--left {
    justify-content: flex-end;
  }

  &--md-down-columns {
    @include respond-up-to(md) {
      flex-direction: column;
    }
  }

  &--row-sm-down {
    @include respond-up-to(sm) {
      flex-direction: row;
    }
  }

  &--row-md-down {
    @include respond-up-to(md) {
      flex-direction: row;
    }
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  &--grow {
    flex-grow: 1;
  }

  &--row-sm-column {
    flex-direction: row;

    @include respond-to(sm) {
      flex-direction: column;
    }
  }
}

.flex--order-1 {
  order: 1;
}

.flex--order-2 {
  order: 2;
}

.flex-stop {
  &--xxs {
    @media (max-width: #{$breakpoint-xs-min - 1px}) {
      display: block !important;
    }
  }

  &--xs {
    @media (min-width: #{$breakpoint-xs-min}) and (max-width: #{$breakpoint-xs-max}) {
      display: block !important;
    }
  }

  &--sm {
    @media (min-width: #{$breakpoint-sm-min}) and (max-width: #{$breakpoint-sm-max}) {
      display: block !important;
    }
  }

  &--md {
    @media (min-width: #{$breakpoint-md-min}) and (max-width: #{$breakpoint-md-max}) {
      display: block !important;
    }
  }

  &--lg {
    @media (min-width: #{$breakpoint-lg-min}) {
      display: block !important;
    }
  }

  &--xs-up {
    @media (min-width: #{$breakpoint-xs-min}) {
      display: block !important;
    }
  }

  &--sm-up {
    @media (min-width: #{$breakpoint-sm-min}) {
      display: block !important;
    }
  }

  &--md-up {
    @media (min-width: #{$breakpoint-md-min}) {
      display: block !important;
    }
  }

  &--lg-up {
    @media (min-width: #{$breakpoint-lg-min}) {
      display: block !important;
    }
  }

  &--xs-down {
    @media (max-width: #{$breakpoint-xs-min - 1px}) {
      display: block !important;
    }
  }

  &--sm-down {
    @media (max-width: #{$breakpoint-xs-max}) {
      display: block !important;
    }
  }

  &--md-down {
    @media (max-width: #{$breakpoint-sm-max}) {
      display: block !important;
    }
  }

  &--lg-down {
    @media (max-width: #{$breakpoint-md-max}) {
      display: block !important;
    }
  }
}
