@mixin offContainerDistance($property) {
  @include respond-to(lg) {
    $property-distance: $max-width-lg / 2;
    #{$property}: calc(50vw - #{$property-distance});
  }
}

@mixin containerDistance($property) {
  #{$property}: $container-padding;

  @include respond-to(md) {
    #{$property}: $container-padding-md;
  }

  @include respond-to(lg) {
    #{$property}: $container-padding-lg;
  }
}

@mixin fullContainerDistance($property) {
  $custom-break-point: $max-width-lg;
  #{$property}: $container-padding;

  @include respond-to(md) {
    #{$property}: $container-padding-md;
  }

  @include respond-to(lg) {
    #{$property}: $container-padding-lg;
  }

  @include respond-to-custom($custom-break-point) {
    $property-distance: $max-width-lg / 2;
    #{$property}: calc(50vw - #{$property-distance} + #{$container-padding-lg});
  }
}
