/**
 * Module: Container
 *
 */

.container {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;

  @include containerDistance(padding-left);
  @include containerDistance(padding-right);

  &--reference {
    @extend .position--relative;
  }

  &--full-height {
    height: 100%;
  }

  &--box {
    max-width: none;
  }

  &--inner {
    width: auto;
  }

  &--no-right-padding-md-down {
    @include respond-up-to(md) {
      padding-right: 0;
    }
  }
}

.container__padding {
  @include containerDistance(padding-left);
  @include containerDistance(padding-right);
}

.container__relative {
  position: relative;

  @include containerDistance(right);
}
