.swiper-button-prev,
.swiper-button-next {
  z-index: 20;
  transform: translate(0, -50%);
  width: 10%;
  height: 100%;
  margin: 0;
  position: absolute;
  background-color: transparent;
  transition: background-color $animation-timing $animation-default-bezier-curve, opacity $animation-timing $animation-default-bezier-curve;

  &.button-fade {
    opacity: 0;
  }

  .slider--full & {
    @include respond-up-to(md) {
      height: 50vh;
      top: 0;
      transform: translateY(0);
    }
  }

  .slider--full .first-slide & {
    @include respond-up-to(md) {
      height: 100vh;
    }

    @include respond-to(md) {
      background-color: $gray-lighter;
    }
  }

  @extend .type--white;

  @include respond-up-to(md) {
    display: none;
  }

  .slider--instagram & {
    width: 60px;
    background-color: rgba(0, 0, 0, 0.5);

    .slider__icon {
      font-size: 3em;
    }
  }

  .slider--responsive & {
    @include respond-to(md) {
      display: none;
    }
  }

  .slider--design-type & {
    width: 11px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity $animation-timing ease-in;

    .slider__icon {
      font-size: 3em;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }

    @include respond-to(sm) {
      width: 160px;
    }
  }

  .slider--full & {
    width: 15%;

    @include respond-to(sm) {
      width: 32px;
    }

    @include respond-to(lg) {
      width: 60px;
    }

    .slider__icon {
      font-size: 3.5em;
      color: $color-yellow;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }

    @include respond-up-to(md) {
      display: block;
    }
  }
}

.swiper-button-prev {
  left: 0;

  .slider--full & {
    @include respond-to(md) {
      left: -50vw;
    }
  }

  .slider--design-type & {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.swiper-button-next {
  right: 0;
}

.swiper-container {
  &--special {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &--normal--md-down {
    @include respond-up-to(md) {
      position: relative;
      height: auto;
    }
  }
}

.swiper-button-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .slider--full .first-slide & {
    @include respond-to(md) {
      color: $color-yellow;
    }
  }
}

.slider {
  position: relative;
  min-height: 100%;

  &.slider--one-slide {
    .slider__slide {
      width: 100%;
    }
  }

  &.slider--responsive {
    @include respond-to(md) {
      .slider__slide {
        &:not(:first-child) {
          display: none;
        }

        width: 100%;
      }
    }
  }

  .slider__icon {
    font-size: 4em;
    color: $color-white;
  }
}

.slider__slide {
  overflow: hidden;
  position: relative;
  height: auto;

  .ratioBox__content {
    background-size: cover;
    background-position: center;
  }

  &--full {
    height: 100%;
  }

  video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &.cover__element--narrow {
      height: auto;
      width: 100%;
    }

    &.cover__element--wide {
      height: 100%;
      width: auto;
    }
  }

  .slider--design-type & {
    width: calc(100% - 24px - 11px);

    @include respond-to(sm) {
      width: calc(100% - 24px - 80px);
    }

    @include respond-to(md) {
      width: calc(100% - 24px - 160px);
    }
  }

  .slider--one-slide & {
    width: calc(100% - #{$container-padding});

    @include respond-to(md) {
      width: calc(100% - #{$container-padding-md});
    }

    @include respond-to(lg) {
      width: calc(100% - #{$container-padding-lg});
    }
  }

  .slider--full & {
    @include respond-up-to(md) {
      min-height: calc(100vh - #{$header-height-sm})!important;

      .admin-bar & {
        min-height: calc(100vh - #{$header-height-sm} - 46px)!important;
      }
    }
  }
}

.slider__indicators {
  @extend .flex;
  position: absolute;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  z-index: 10;
  bottom: calc(100% + #{$separator-md});
  padding-right: $container-padding;

  .slider--full & {
    bottom: 20px;
    align-items: center;
    left: 0;
    justify-content: center;

    @include respond-to(md) {
      padding-left: $container-padding-md;
      justify-content: flex-start;
      bottom: 40px;
    }

    @include respond-to(lg) {
      padding-left: $container-padding-lg;
    }
  }

  &--static {
    position: static;

    @include respond-to(md) {
      padding-left: 0!important;
    }
  }
}

.slider__indicator {
  display: block;
  margin: 0 4px;
}

.slider__indicatorBox {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity $animation-timing linear, background-color $animation-timing linear;
  opacity: 0.6;
  background-color: $color-gray-darker;

  &:hover,
  &.current {
    opacity: 1;
    background-color: $color-white;
  }
}

.swiper-pagination-bullet {
  display: block;
  margin: 0 4px;
  background-color: $color-gray;
  opacity: 0.5;

  &-active {
    width: 16px;
    height: 16px;
    opacity: 1;
    background-color: $color-yellow;
  }
}

.slider__button-text {
  @extend .type;
  @extend .type--white;
  @extend .type--p1;
  position: absolute;
  display: none;
  top: 50%;
  transform: translateY(-50%);
  right: 140%;
  white-space: nowrap;

  .slider--full .first-slide & {
    display: block;
  }
}
