/**
  * Paragraph basic styles.
  *
  */

p {
  @extend .type;
  @extend .type--p1;

  .post-paragraph & {
    margin-bottom: 20px;
  }
}
