/**
 * Fonts: Font definitions
 *
 */
@import url("//hello.myfonts.net/count/330d8a");

@font-face {
  font-family: markpro;
  src: url('../assets/fonts/330D8A_0_0.eot');
  src: url('../assets/fonts/330D8A_0_0.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/330D8A_0_0.woff2') format('woff2'), url('../assets/fonts/330D8A_0_0.woff') format('woff'), url('../assets/fonts/330D8A_0_0.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: markpro;
  src: url('../assets/fonts/330D8A_1_0.eot');
  src: url('../assets/fonts/330D8A_1_0.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/330D8A_1_0.woff2') format('woff2'), url('../assets/fonts/330D8A_1_0.woff') format('woff'), url('../assets/fonts/330D8A_1_0.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: markpro;
  src: url('../assets/fonts/330D8A_2_0.eot');
  src: url('../assets/fonts/330D8A_2_0.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/330D8A_2_0.woff2') format('woff2'), url('../assets/fonts/330D8A_2_0.woff') format('woff'), url('../assets/fonts/330D8A_2_0.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

//@font-face {
//  font-family: markpro;
//  src: url('../assets/fonts/MarkPro-Medium.eot');
//  src: url('../assets/fonts/MarkPro-Medium.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/MarkPro-Medium.woff2') format('woff2'), url('../assets/fonts/MarkPro-Medium.woff') format('woff'), url('../assets/fonts/MarkPro-Medium.ttf') format('truetype');
//  font-weight: 500;
//  font-style: normal;
//}
//
//@font-face {
//  font-family: markpro;
//  src: url('../assets/fonts/MarkPro-Bold.eot');
//  src: url('../assets/fonts/MarkPro-Bold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/MarkPro-Bold.woff2') format('woff2'), url('../assets/fonts/MarkPro-Bold.woff') format('woff'), url('../assets/fonts/MarkPro-Bold.ttf') format('truetype');
//  font-weight: 600;
//  font-style: normal;
//}

$font-markpro: markpro;
