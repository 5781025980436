/**
 * Utility: Space
 * @author: Konstantinos Kataras
 *
 */

// $positions

$positions: (
  vertical: (top, bottom),
  horizontal: (left, right),
) !default;

$separators: (
  // Define the separator values like the example bellow
  // sm: 12px;
) !default;

.space {
  @each $direction, $position in $positions {
    @each $edge in map_get($positions, $direction) {
      @each $separator-name, $separator in $separators {
        &--outer-#{$edge}-#{$separator-name} {
          margin-#{$edge}: $separator;
        }

        &--inner-#{$edge}-#{$separator-name} {
          padding-#{$edge}: $separator;
        }
      }
    }
  }
}
