// Menu

.menu {
  background-color: $color-gray-darkest;
  box-shadow: 0 0 120px 0 rgba(0, 0, 0, 0.5);

  @include respond-to(md) {
    background-color: transparent;
    display: flex;
    flex-wrap: nowrap;
    box-shadow: none;
    justify-content: flex-end;
    width: 100%;
  }

  &--subMenu {
    display: block;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height $animation-timing ease-in-out, opacity $animation-timing linear;

    @include respond-up-to(md) {
      position: static;
      max-width: none;
      width: 100%;
      left: 0;
      top: 0;
    }

    @include respond-to(md) {
      position: absolute;
      display: block;
      z-index: 0;
      left: -10px;
      top: 100%;
      width: auto;
      background-color: $color-white;
      color: $color-gray;
      justify-content: space-around;
      border: solid 1px rgba(24, 24, 25, 0.1);
      box-shadow: 0 0 120px 0 rgba(0, 0, 0, 0.2);
      min-width: calc(100% + 20px);
    }
  }

  &--main {
    @include respond-up-to(md) {
      .menu-item.open:not(:hover),
      .menu-item.open:not(:active),
      .menu-item.open:not(:focus) {
        .menu--subMenu {
          max-height: 100vh;
          opacity: 1;
        }
      }
    }

    @include respond-to(md) {
      .menu-item:hover {
        .menu--subMenu {
          max-height: 100vh;
          opacity: 1;
        }
      }
    }
  }
}

.menu-item {
  display: flex;
  align-items: stretch;
  position: relative;

  &:last-child {
    .menu--subMenu & {
      .menu__link {
        border-bottom: none!important;
      }
    }
  }

  .menu--subMenu & {
    display: block;

    .menu__link {
      margin: 0;
      padding: $container-padding;
      width: 100%;
      text-align: left;
      align-items: flex-start;
      justify-content: flex-start;

      &.current-menu-item,
      &:hover:not(:active) {
        @include respond-to(md) {
          border-bottom: 1px solid $color-gray-light;
          background-color: $color-gray-lighter;
        }
      }

      @include respond-to(md) {
        padding: 20px 30px 20px 26px;
        border-top: 1px solid transparent !important;
        border-bottom: 1px solid $color-gray-light !important;
      }
    }

    @include respond-up-to(md) {
      border: none;
    }
  }

  &.current-menu-item,
  &.current-menu-ancestor,
  &:hover {
    .menu__link {
      @include respond-to(md) {
        border-bottom: 4px solid $color-yellow;
        color: $color-black;
      }
    }
  }

  &.current-menu-ancestor,
  &.current-menu-item {
    .menu__link {
      opacity: 1;
    }
  }

  @include respond-up-to(md) {
    background-color: $color-gray-darker;
    flex-direction: column;
    border-bottom: 1px solid $color-gray-darkest;

    &.open {
      background-color: $color-gray-darkest;
    }

    .menu-item.open & {
      background-color: $color-gray-darkest;
    }
  }

  @include respond-to-range(1024px, 1100px) {
    a {
      font-size: 14px;
    }
  }
}

.menu__link {
  @extend .flex;
  @extend .type;
  @extend .type--p1;
  @extend .type--gray-dark;
  @extend .type--500;

  display: flex;
  padding: $container-padding;
  border-left: 0;
  border-right: 0;
  line-height: 1;
  white-space: nowrap;
  justify-content: space-between;
  position: relative;
  z-index: 10;

  .icon {
    margin-right: 4px;

    .menu-item.open & {
      @extend .icon--arrow-up;
    }
  }

  @include respond-up-to(md) {
    font-size: 22px;
    display: block;
    width: 100%;
    position: relative;
    color: $color-white;

    &:hover {
      color: $color-white;
    }
  }

  @include respond-to(md) {
    opacity: 0.8;
    margin: 0 $separator-xxxs;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    transition: border-color 0.2s linear;
  }

  @include respond-to-custom(1400px) {
    margin: 0 $separator-xs;
  }
}
