.customGrid {

}

.customGrid__element {
  padding: $grid-gutter-width / 2;
  width: 100%;

  @include respond-to(sm) {
    @include getWidth(2);
  }

  @include respond-to(md) {
    @include getWidth(5);
  }
}
