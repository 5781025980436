/**
 * Trigger - Add a trigger under a selector.
 */

@mixin trigger($selector) {
  #{$selector} &,
  &#{$selector} {
    @content;
  }
}
