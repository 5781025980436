.form {
  width: 100%;
  display: block;
  position: relative;

  &--white {
    h3 {
      color: $color-white;
    }

    .gfield {
      color: $color-white;

      .sidebar-widget & {
        color: $color-gray-dark;
      }

      input:not([type="checkbox"]),
      select,
      textarea {
        border-color: rgba(255, 255, 255, 0.7);
        color: $color-white;

        .sidebar-widget & {
          border-color: rgba(138, 138, 141, 0.5);
          color: $color-gray-dark;
        }

        @include style-placeholder {
          color: $color-white!important;
          opacity: 0.7;

          .sidebar-widget & {
            color: $color-gray-dark !important;
          }
        }
      }
    }
  }

  option {
    color: $color-black;
  }
}

.gfield {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  color: $color-gray-darker;

  &.gform_validation_container.gfield_visibility_ {
    display: none;
  }

  input,
  select,
  textarea {
    background-color: transparent;
    text-decoration: none;
    box-shadow: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    overflow: auto;
    appearance: none;
    border: none;
    line-height: 16px;
    border-bottom: 2px solid rgba(138, 138, 141, 0.5);
    padding: 0;
    width: 100%;
    display: block;
    border-radius: 0;
    color: $color-gray-darker;
    transition: border-color 0.3s linear;

    @extend .type;
    @extend .type--markpro;
    @extend .type--button;

    @include style-placeholder {
      color: $color-gray-dark;
      opacity: 0.5;
    }
  }

  input[type="text"] {
    line-height: 42px;
    height: 42px;
  }

  select {
    cursor: pointer;
    color: $color-gray;
    line-height: 42px;
    height: 42px;

    .form--white & {
      color: rgba(255, 255, 255, 0.7);
    }

    &::-ms-expand {
      display: none;
    }

    &.selected {
      color: $color-gray-darker;

      .form--white & {
        color: $color-white;
      }
    }
  }

  input {
    &[type="checkbox"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      visibility: hidden;
      width: 0;
      height: 0;
      margin: 0;

      + label {
        position: relative;
        padding-left: 35px;

        .form--white & {
          &:after {
            background-color: $color-white;
            opacity: 0.2;
          }
        }

        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 24px;
          height: 24px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          border: none;
          background-color: $color-gray;
          border-radius: 2px;
          margin: 0;
          padding: 0;
          cursor: pointer;
          transition: background-color $animation-timing $animation-default-bezier-curve, opacity $animation-timing $animation-default-bezier-curve;
          box-shadow: inset 0 0 0 1px $color-gray;
        }

        &:before {
          content: 'L';
          position: absolute;
          transform: translateY(-50%) rotate(220deg) scale(1, -1);
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 0;
          top: 50%;
          width: 24px;
          height: 24px;
          transition: opacity $animation-timing $animation-default-bezier-curve;
          color: $color-white;
          opacity: 0;

          .form--white & {
            color: $color-gray-darker;
          }
        }

        &:hover {
          &:after {
            background-color: $color-gray-dark;
          }

          .form--white & {
            &:after {
              background-color: $color-white;
              opacity: 0.4;
            }
          }
        }
      }

      [disabled] {
        + label {
          &:after {
            background-color: $color-gray-darker;

            .form--white & {
              background-color: $color-white;
              opacity: 0.6;
            }
          }
        }
      }

      &:checked {
        + label {
          &:after {
            background-color: $color-yellow;

            .form--white & {
              background-color: $color-white;
              opacity: 1;
            }
          }

          &:before {
            opacity: 1;
          }
        }

        &:not([disabled]) {
          + label {
            &:hover {
              &:after {
                box-shadow: inset 0 0 0 1px $color-gray;
              }
            }
          }
        }

        &[disabled],
        &[disabled]:hover {
          + label {
            &:after {
              background-color: $color-gray;

              .form--white & {
                background-color: $color-white;
                opacity: 0.4;
              }
            }
          }
        }
      }
    }

    &[type="radio"] {
      -webkit-appearance: none;
      -moz-appearance: none!important;
      appearance: none;
      margin: 0;
      padding: 0;
      width: 0;
      height: 0;
      visibility: hidden;

      + label {
        position: relative;
        padding-left: 35px;

        &:before {
          content: '';
          width: 24px;
          height: 24px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          background-color: $color-gray-lighter;
          border-radius: 50%;
          border: 1px solid transparent;
          box-shadow: inset 0 0 0 2px $color-gray-dark;
          margin: 0;
          padding: 0;
          cursor: pointer;
          transition: border-color $animation-timing $animation-default-bezier-curve, background-color $animation-timing $animation-default-bezier-curve, box-shadow $animation-timing $animation-default-bezier-curve;

          .form--white & {
            box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.5);
            background-color: $color-yellow;
          }
        }

        &:hover {
          &:before {
            box-shadow: inset 0 0 0 2px $color-black;

            .form--white & {
              box-shadow: none;
              background-color: $color-yellow-light;
            }
          }
        }
      }

      &[disabled] {
        + label {
          &:hover {
            &:before {
              border: 1px solid $color-black;
              background-color: $color-gray;
              box-shadow: inset 0 0 0 1px $color-black;
            }
          }
        }
      }

      &:checked {
        + label {
          &:before {
            background-color: $color-white;
            box-shadow:inset 0 0 0 6px $color-yellow;

            .form--white & {
              background-color: $color-yellow;
              box-shadow:inset 0 0 0 6px $color-white;
            }
          }
        }

        &:not([disabled]) {
          + label {
            &:before {
              border: 1px solid $color-gray;

              .form--white & {
                border: none;
              }
            }
          }
        }

        &[disabled] {
          + label {
            &:before {
              background-color: $color-white;
              box-shadow: inset 0 0 0 5px $color-gray-lighter;
              border: 2px solid $color-gray-dark;
            }
          }
        }
      }
    }
  }

  &.gfield_error {
    input:not([type="checkbox"]):not([type="radio"]),
    select,
    textarea {
      border-color: $color-red !important;

      .form--white & {
        border-color: $color-black !important;
      }

      .form--subscription & {
        border-color: $color-black !important;
      }
    }
  }

  label {
    @extend .type;
    @extend .type--button;
  }

  textarea {
    max-height: 100px;
    resize: none;
  }

  .validation_message {
    color: $color-black;
    opacity: 0.6;
    margin-top: $separator-xs;

    .sidebar-widget & {
      color: $color-red;
      font-size: 13px;
      margin-top: 0;
    }
  }
}

.form__field--hidden-on-revealing {
  .form--revealing & {
    display: none;
  }
}

.js-hidden-field {
  &.flex--vertical-center {
    display: flex;

    .form--revealing.form--revealed & {
      display: flex!important;
    }
  }
}
