/**
 * Module: Section
 *
 */

.section {
  display: block;
  position: relative;
  width: 100%;
  padding: $section-padding 0;

  @include respond-to(sm) {
    padding: $section-padding-sm 0;
  }

  @include respond-to(md) {
    padding: $section-padding-md 0;
  }

  &--half-padded {
    padding: $section-padding 0;

    @include respond-to(sm) {
      padding: $separator-md 0;
    }
  }

  &--half-padded-bottom {
    padding-bottom: $section-padding;

    @include respond-to(sm) {
      padding-bottom: $separator-md;
    }
  }

  &--unpadded {
    padding: 0!important;
  }

  &--unpadded-top {
    padding-top: 0!important;
  }

  &--clip {
    overflow: hidden;
  }

  &--full-device-height {
    height: calc(100vh - #{$header-height-sm});

    @include respond-to(sm) {
      height: calc(100vh - #{$header-height-sm-up});
    }

    .admin-bar & {
      height: calc(100vh - #{$header-height-sm} - 46px);

      @include respond-to(sm) {
        height: calc(100vh - #{$header-height-sm-up} - 46px);
      }

      @include respond-to-custom(783px) {
        height: calc(100vh - #{$header-height-sm-up} - 32px);
      }
    }
  }

  &--full-device-height-md-up {
    @include respond-to(md) {
      height: auto;
    }
  }

  &--half-height-mobile {
    @include respond-up-to(sm) {
      max-height: 50vh;
    }
  }

  &--auto-height-md-down {
    @include respond-up-to(md) {
      height: auto;
    }
  }

  &--half-device-height {

  }


  @each $key, $color in $colors {
    &--#{$key} {
      background-color: $color;
    }
  }

  &--transparent-sm-down {
    @include respond-up-to(sm) {
      background-color: transparent;
    }
  }

  &--transparent-md-down {
    @include respond-up-to(md) {
      background-color: transparent;
    }
  }

  &--themed {
    &.section--yellow {
      .type--yellow {
        color: $color-white!important;
      }
    }
  }
}

.section__background {
  @extend .position;
  @extend .position--stressed;
  @extend .background;
}

.section__min-height-container {
  min-height: 368px;

  @include respond-to(sm) {
    min-height: 544px;
  }
}
