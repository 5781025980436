@keyframes line1Forwards {
  from {
    transform: rotate(0deg) translate(0, 0);
  }
  to {
    transform: rotate(405deg) translate(5px, 5px);
  }
}

@keyframes line1Backwards {
  from {
    transform: rotate(405deg) translate(5px, 5px);
  }
  to {
    transform: rotate(0deg) translate(0, 0);
  }
}

.line1Forwards {
  animation-name: line1Forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.line1Backwards {
  animation-name: line1Backwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@keyframes line2Forwards {
  from {
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(360deg);
    opacity: 0;
  }
}

@keyframes line2Backwards {
  from {
    transform: rotate(3600deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}

.line2Forwards {
  animation-name: line2Forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.line2Backwards {
  animation-name: line2Backwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

@keyframes line3Forwards {
  from {
    transform: rotate(0deg) translate(0, 0);
  }
  to {
    transform: rotate(315deg) translate(5px, -5px);
  }
}

@keyframes line3Backwards {
  from {
    transform: rotate(315deg) translate(5px, -5px);
  }
  to {
    transform: rotate(0deg) translate(0, 0);
  }
}

.line3Forwards {
  animation-name: line3Forwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.line3Backwards {
  animation-name: line3Backwards;
  animation-iteration-count: 1;
  animation-timing-function: linear;
}

.menuButt {
  width: 28px;
  min-height: 16px;
  position: relative;
  transition: min-height 0.3s linear;
}

.menuButt__line {
  display: block;
  position: absolute;
  width: 28px;
  height: 2px;
  background-color: $color-gray-dark;

  .animate {
    animation-duration: 0.3s;
    animation-fill-mode: both;
  }

  &--line1 {
    top: 0;
    @extend .line1Backwards;

    .menuButt--close & {
      @extend .line1Forwards;
    }
  }

  &--line2 {
    top: calc(50% - 1px);

    @extend .line2Backwards;

    .menuButt--close & {
      @extend .line2Forwards;
    }
  }

  &--line3 {
    bottom: 0;

    @extend .line3Backwards;

    .menuButt--close & {
      @extend .line3Forwards;
    }
  }
}
