.logo {
  &--main {
    width: 150px;
    height: 21px;

    @include respond-to(sm) {
      width: 180px;
      height: 24px;
    }
  }

  &--deco-responsive {
    height: 50px;
    width: auto;

    @include respond-up-to(sm) {
      transform: translateY(-5px);
      height: 35px;
    }

    @include respond-up-to(xs) {
      transform: translateY(0);
      height: 30px;
    }
  }
}
