/**
 * Module: Type
 *
 * @author: Konstantinos Kataras
 */

@import "../mixins/media-queries";

$colors: ()!default;
$font-families: ()!default;
$font-weights: ()!default;
$breakpoints: ()!default;

.type {
  -ms-word-break: break-word;
  font-style: normal;
  font-stretch: normal;

  &--h1A {
    font-size: 52px;
    font-weight: 600;
    line-height: 1.08;
    letter-spacing: -2px;

    @include respond-to(sm) {
      font-size: 150px;
      line-height: 1.07;
      letter-spacing: -3px;
    }
  }

  &--h1 {
    font-size: 26px;
    font-weight: 600;
    line-height: 1.31;
    letter-spacing: -0.7px;

    @include respond-to(sm) {
      font-size: 40px;
      line-height: 1.1;
      letter-spacing: -1.1px;
    }

    @include respond-to(md) {
      font-size: 47px;
      line-height: 1.12;
      letter-spacing: -0.8px;
    }
  }

  &--h1B {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.18;
    letter-spacing: -0.6px;

    @include respond-to(sm) {
      font-size: 28px;
      line-height: 1.29;
      letter-spacing: -0.8px;
    }

    @include respond-to(md) {
      font-size: 41px;
      line-height: 1.17;
      letter-spacing: -1.4px;
    }
  }

  &--h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.27;
    letter-spacing: -0.5px;

    @include respond-to(sm) {
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: -0.4px;
    }

    @include respond-to(md) {
      font-size: 29px;
      line-height: 1.31;
      letter-spacing: -0.7px;
    }
  }

  &--h2-md-up {
    @include respond-to(md) {
      font-size: 29px !important;
      line-height: 1.17 !important;
      letter-spacing: -0.8px !important;
      font-weight: 600 !important;
    }
  }

  &--h2-sm-up {
    @include respond-to(sm) {
      font-size: 29px !important;
      line-height: 1.17 !important;
      letter-spacing: -0.8px !important;
      font-weight: 600 !important;
    }
  }

  &--h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.67;

    @include respond-to(sm) {
      font-size: 25px;
      line-height: 1.43;
      letter-spacing: -0.5px;
    }
  }

  &--h3B {
    font-size: 18px;
    font-weight: 600;
    line-height: 1.67;

    @include respond-to(sm) {
      font-size: 22px;
      line-height: 1.17;
      letter-spacing: -0.8px;
    }
  }

  &--h4 {
    font-size: 12px;
    font-weight: 500;
    line-height: 2.0;
    letter-spacing: 1px;

    @include respond-to(sm) {
      font-size: 29px;
      line-height: 1.17;
      letter-spacing: -0.8px;
    }
  }

  &--h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 2.0;
    letter-spacing: 1px;

    @include respond-to(sm) {
      font-size: 29px;
      line-height: 1.17;
      letter-spacing: -0.8px;
    }
  }

  &--p1 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.2px;

    @include respond-to(sm) {
      font-size: 18px;
      line-height: 1.33;
      letter-spacing: -0.3px;
    }
  }

  &--p2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.14;
    letter-spacing: -0.3px;
  }

  &--p3 {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1px;
  }

  &--link {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;

    @include respond-to(sm) {
      font-size: 16px;
      line-height: 1.78;
    }
  }

  &--wide-spaced {
    letter-spacing: 1px;
  }

  &--button {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.78;
    letter-spacing: -0.5px;
  }

  &--height-spaced {
    line-height: 1.8em;
  }

  &--height-spaced-sm {
    line-height: 1.2em;
  }

  &--inherit {
    font-size: inherit !important;
    line-height: inherit !important;
    letter-spacing: inherit !important;
    font-weight: inherit !important;
    color: inherit !important;

    @include respond-to(sm) {
      font-size: inherit !important;
      line-height: inherit !important;
      letter-spacing: inherit !important;
      font-weight: inherit !important;
      color: inherit !important;
    }

    @include respond-to(md) {
      font-size: inherit !important;
      line-height: inherit !important;
      letter-spacing: inherit !important;
      font-weight: inherit !important;
      color: inherit !important;
    }
  }

  &--caps {
    text-transform: uppercase;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--centered {
    text-align: center;
  }

  &--centered-sm-up {
    @include respond-to(sm) {
      text-align: center;
    }
  }

  &--one-line {
    white-space: nowrap;
  }

  &--italic {
    font-style: italic;
  }

  &--underlined {
    text-decoration: underline;
  }

  &--inactive {
    pointer-events: none;
  }

  &--opaque {
    opacity: 0.7;
  }

  @each $breakpoint-name, $breakpoint in $breakpoints {
    &--left-up-to-#{$breakpoint-name} {
      @include respond-up-to($breakpoint-name) {
        text-align: left;
      }
    }
  }

  @each $breakpoint-name, $breakpoint in $breakpoints {
    &--right-up-to-#{$breakpoint-name} {
      @include respond-up-to($breakpoint-name) {
        text-align: right;
      }
    }
  }

  @each $color-name, $color in $colors {
    &--#{$color-name} {
      color: $color;
    }
  }

  @each $font-family-name, $font-family in $font-families {
    &--#{$font-family-name} {
      font-family: $font-family !important;
    }
  }

  @each $font-weight in $font-weights {
    &--#{$font-weight} {
      font-weight: $font-weight;
    }
  }
}
