.sticky-nav-indicator {
  position: relative;
  z-index: 1000;
  padding-bottom: $container-padding;

  @include respond-to(sm) {
    position: absolute;
    left: -9999px;
    visibility: hidden;
    pointer-events: none;
  }
}

.sticky-nav-indicator__link {
  font-size: 16px;
  line-height: 1.5
}
