/*
* Scale animations
*
*/

@keyframes scale-in-out {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

@mixin scale-in-out {
  animation-name: scale-in-out;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
}

.scale-in-out {
  @include scale-in-out;
}
