.c-design-type {
  margin-top: 32px;

  @include respond-to(sm) {
    margin-top: 54px;
  }
}

.c-design-type-actions {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
  width: 100%;
}

.c-design-type-actions__book {
  flex: 1;
  margin-right: 10px;
  padding-top: 16px;
}

.c-design-type-actions__share {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 16px;
  padding-right: 19px;

  > li:not(:last-child) {
    margin-right: 10px;
  }

  @include respond-up-to(xs) {
    width: 100%;
  }
}
