.listGrid__elem {
  @extend .col-xs-12;
  @extend .col-sm-4;

  &:nth-of-type(1):not(.close),
  &:nth-of-type(2):not(.close) {
    @extend .col-xs-12;
    @extend .col-sm-6;
  }

  &.close {
    display: none;
  }
}