.background {
  background-repeat: no-repeat;
  background-position: center;

  &--cover {
    background-size: cover;
  }

  &--contain {
    background-size: contain;
  }

  &--full-size {
    background-size: 100% auto;
  }

  &--hover-scale {
    transition: transform $animation-timing $animation-default-bezier-curve;

    &:hover,
    .background__hover-trigger:hover & {
      transform: scale(1.05);
    }
  }
}
