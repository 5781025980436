/**
 * Utility: Padding
 * @author: Konstantinos Kataras
 *
 */

.padding {
  &--off {
    padding: 0 !important;
  }

  &--sides-container {
    $padding-sides: $container-max-width / 2;

    padding: $container-padding;

    @media (min-width: 1370px) {
      padding: 88px 88px 88px calc(50vw - #{$padding-sides});
    }
  }

  &--bottom-spaced {
    padding-bottom: $separator-lg;
  }

  &--bottom-left {
    padding: 0 0 $separator-md $separator-md;

    @include respond-to(md) {
      padding: 0 0 $separator-md $separator-lg;
    }
  }

  &--right {
    padding-right: $separator-xs;

    @include respond-to(md) {
      padding-right: $separator-md;
    }
  }

  &--left-md {
    padding-left: 0;

    @include respond-to(md) {
      padding-left: $separator-sm;
    }
  }

  &--left-md-big {
    padding-left: 0;

    @include respond-to(md) {
      padding-left: $separator-md;
    }
  }

  &--right-lg {
    @include respond-to(lg) {
      padding-right: $separator-xl;
    }
  }

  &--bottom-grid-space {
    padding-bottom: $grid-gutter-width;
  }

  &--bottom-content {
    padding-bottom: $separator-sm;

    @include respond-to(sm) {
      padding-bottom: $separator-md;
    }

    @include respond-to(md) {
      padding-bottom: $separator-md + $separator-xxxs;
    }
  }

  &--top-md-up {
    @include respond-to(md) {
      padding-top: $separator-sm;
    }
  }

  &--top-md-down-sm {
    @include respond-up-to(md) {
      padding-top: $separator-sm;
    }
  }

  &--top-responsive {
    padding-top: $separator-xs;

    @include respond-to(sm) {
      padding-top: $separator-md;
    }
  }

  &--top-responsive-md {
    padding-top: $separator-sm;

    @include respond-to(md) {
      padding-top: $separator-md + $separator-sm;
    }
  }
}

.paddingBox {
  @include respond-to(sm) {
    padding: 46px 36px;
  }

  &--responsive {
    padding: 24px 32px;

    @include respond-to(md) {
      padding: 36px 27px 36px 46px;
    }
  }

  &--responsive-md-up-sm {
    @include respond-to(md) {
      padding: 36px 27px 36px 40px;
    }
  }

  &--md-down-sides-off {
    @include respond-up-to(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &--sm {
    padding: $separator-sm;

    @include respond-to(sm) {
      padding: $separator-md;
    }
  }
}
