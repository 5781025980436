.tabs {}

.tabs__button {}

.tabs__buttonText {
  .tabs__button--active & {
    @extend .type--gray-darker;
  }
}

.tabs__tabContainer {
  position: relative;
}

.tabs__tab {
  .tabs--enabled & {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    transition: opacity $animation-timing $animation-default-bezier-curve;
  }

  &--open {
    .tabs--enabled & {
      position: relative;
      opacity: 1;
      z-index: 2;
    }
  }
}
