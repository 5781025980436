// Links

a {
  color: $color-black;
  text-decoration: none;

  @extend .type;
  @extend .type--link;

  &:hover {
    color: $color-black;
  }
}

.link-control {
  a {
    &:not(.button--white):not(.icon__hover-scale-animation) {
      font-weight: 500;
      color: $color-gray;
      transition: color $animation-timing $animation-default-bezier-curve;
      text-decoration: underline;

      &:hover {
        color: $color-black;
      }
    }
  }
}
