.footer__title {
  @extend .type;
  @extend .type--p1;
  @extend .type--white;

  @include respond-to(sm) {
    margin-bottom: $separator-sm;
  }
}

.footer__description {
  @extend .type--gray;
  @extend .type--p1;
  @extend .type--500;

  @include respond-up-to(sm) {
    text-decoration: underline;
  }
}

.footer__link {
  @extend .type;
  @extend .footer__description;
  display: block;
  word-break: break-all;
}

@include respond-up-to(md) {
  .footer__copyright {
    display: block;
    text-align: center;
  }
}
