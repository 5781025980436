/*
 * Iconfont
 *
 */

@font-face {
  font-family: iconfont;
  src: url('../assets/fonts/iconfont.eot');
  src: url('../assets/fonts/iconfont.eot?#iefix') format('eot'), url('../assets/fonts/iconfont.woff') format('woff'), url('../assets/fonts/iconfont.woff2') format('woff2'), url('../assets/fonts/iconfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.icon {
  display: inline-block;
  font-family: iconfont;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: top;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon--arrow-down::before {
  content: '\EA01';
}

.icon--arrow-down-copy::after {
  content: '\EA01';
}

.icon--arrow-left::before {
  content: '\EA02';
}

.icon--arrow-left-copy::after {
  content: '\EA02';
}

.icon--arrow-right::before {
  content: '\EA03';
}

.icon--arrow-right-copy::after {
  content: '\EA03';
}

.icon--arrow-up-down::before {
  content: '\EA04';
}

.icon--arrow-up-down-copy::after {
  content: '\EA04';
}

.icon--arrow-up::before {
  content: '\EA05';
}

.icon--arrow-up-copy::after {
  content: '\EA05';
}

.icon--badge::before {
  content: '\EA06';
}

.icon--badge-copy::after {
  content: '\EA06';
}

.icon--cap::before {
  content: '\EA07';
}

.icon--cap-copy::after {
  content: '\EA07';
}

.icon--close::before {
  content: '\EA08';
}

.icon--close-copy::after {
  content: '\EA08';
}

.icon--diamond::before {
  content: '\EA09';
}

.icon--diamond-copy::after {
  content: '\EA09';
}

.icon--dollar::before {
  content: '\EA0A';
}

.icon--dollar-copy::after {
  content: '\EA0A';
}

.icon--down-circle::before {
  content: '\EA0B';
}

.icon--down-circle-copy::after {
  content: '\EA0B';
}

.icon--download-circle::before {
  content: '\EA0C';
}

.icon--download-circle-copy::after {
  content: '\EA0C';
}

.icon--download::before {
  content: '\EA0D';
}

.icon--download-copy::after {
  content: '\EA0D';
}

.icon--facebook::before {
  content: '\EA0E';
}

.icon--facebook-copy::after {
  content: '\EA0E';
}

.icon--gallery::before {
  content: '\EA0F';
}

.icon--gallery-copy::after {
  content: '\EA0F';
}

.icon--instagram::before {
  content: '\EA10';
}

.icon--instagram-copy::after {
  content: '\EA10';
}

.icon--leaf::before {
  content: '\EA11';
}

.icon--leaf-copy::after {
  content: '\EA11';
}

.icon--left-circle::before {
  content: '\EA12';
}

.icon--left-circle-copy::after {
  content: '\EA12';
}

.icon--linkedin::before {
  content: '\EA13';
}

.icon--linkedin-copy::after {
  content: '\EA13';
}

.icon--menu::before {
  content: '\EA14';
}

.icon--menu-copy::after {
  content: '\EA14';
}

.icon--pause::before {
  content: '\EA15';
}

.icon--pause-copy::after {
  content: '\EA15';
}

.icon--pinterest::before {
  content: '\EA16';
}

.icon--pinterest-copy::after {
  content: '\EA16';
}

.icon--play::before {
  content: '\EA17';
}

.icon--play-copy::after {
  content: '\EA17';
}

.icon--plus::before {
  content: '\EA18';
}

.icon--plus-copy::after {
  content: '\EA18';
}

.icon--right-circle::before {
  content: '\EA19';
}

.icon--right-circle-copy::after {
  content: '\EA19';
}

.icon--shedule::before {
  content: '\EA1A';
}

.icon--shedule-copy::after {
  content: '\EA1A';
}

.icon--snapchat::before {
  content: '\EA1B';
}

.icon--snapchat-copy::after {
  content: '\EA1B';
}

.icon--spinner::before {
  content: '\EA1C';
}

.icon--spinner-copy::after {
  content: '\EA1C';
}

.icon--stack::before {
  content: '\EA1D';
}

.icon--stack-copy::after {
  content: '\EA1D';
}

.icon--thumb-up::before {
  content: '\EA1E';
}

.icon--thumb-up-copy::after {
  content: '\EA1E';
}

.icon--tick::before {
  content: '\EA1F';
}

.icon--tick-copy::after {
  content: '\EA1F';
}

.icon--twitter::before {
  content: '\EA20';
}

.icon--twitter-copy::after {
  content: '\EA20';
}

.icon--users::before {
  content: '\EA21';
}

.icon--users-copy::after {
  content: '\EA21';
}

.icon--youtube::before {
  content: '\EA22';
}

.icon--youtube-copy::after {
  content: '\EA22';
}
