/**
 * Container Padding Utility - This utility adds a padding equals to the effect that a container would have.
 *
 * @author: Konstantinos Kataras.
 */

@import "../mixins/container";

.container-padding-left {
  padding-left: $container-padding;

  @include respond-to(md) {
    padding-left: $container-padding-md;
  }

  @include respond-to(lg) {
    padding-left: $container-padding-lg;
  }
}

.container-padding-right {
  padding-right: $container-padding;

  @include respond-to(md) {
    padding-right: $container-padding-md;
  }

  @include respond-to(lg) {
    padding-right: $container-padding-lg;
  }
}
