.project-hero {
  height: auto;
  width: 100%;
  overflow: hidden;
  position: relative;

  @include respond-to(lg) {
    height: calc(100vh - #{$header-height-sm-up});
  }

  // based on section--full-device-height
  .admin-bar & {
      height: calc(100vh - #{$header-height-sm} - 46px);

      @include respond-to(sm) {
        height: calc(100vh - #{$header-height-sm-up} - 46px);
      }

      @include respond-to-custom(783px) {
        height: calc(100vh - #{$header-height-sm-up} - 32px);
      }
    }
}

.project-hero__image {
  display: block;
  width: 100%;
  height: auto;

  @include respond-to(lg) {
    display: none;
  }
}

.project-hero__container-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  padding: $section-padding 0;

  @include respond-to(sm) {
    padding: $section-padding-sm 0;
  }

  @include respond-to(md) {
    padding: $section-padding-md 0;
  }
}
