hr,
.hr {
  text-decoration: none;
  border-bottom: 1px solid $color-gray-open;

  &--top {
    border: none;
    border-top: 1px solid $color-gray-open;
  }

  &--light {
    border-color: $color-gray-lighter;
  }

  &--thick {
    border-width: 2px;
  }

  &--separated {
    padding-top: $separator-xs;
    margin-bottom: $separator-xs;

    @include respond-to(sm) {
      padding-top: $separator-md;
      margin-bottom: $separator-md;
    }
  }
}
