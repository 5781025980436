/**
 * Mixin: Style placeholder
 * styles placeholder for form inputs
 *
 */

@mixin style-placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &::-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }

  &:-moz-placeholder {
    @content
  }
}
