.c-tabs-item {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 0;
  margin-left: 0;

  &:before,
  &:after {
    display: none;
  }
}

.c-tabs-item__header {
  width: 100%;
}

.c-tabs-item__side {
  position: absolute;
  top: 0;
  right: 0;
  width: calc((100% - 40px) / 2);
}

.c-tabs-item__main {
  width: calc((100% - 40px) / 2);
}

.c-tabs-item__action-next {
  display: none;
}

@media only screen and (min-width: $breakpoint-sm) {
  .c-tabs-item__action-next {
    display: flex;
    justify-content: flex-end;
    margin: 20px 15px 0 0;
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .c-tabs-item__header {
    width: 100%;
  }

  .c-tabs-item__side {
    position: initial;
    width: 100%;
  }

  .c-tabs-item__main {
    padding-right: 15px;
    width: 100%;
  }
}

@media only screen and (min-width: $breakpoint-md) {
  .c-tabs-item__action-next {
    margin: 20px 0 0 0;
  }
}
