.sidebar-header {
  position: relative;
  padding: 10px 0 0;
  margin-bottom: 10px;
  display: inline-block;

  span {
    position: relative;
    display: block;
    height: 4px;
    width: 50px;
    margin-top: 5px;
    background-color: $color-yellow;
  }
}

.sidebar-widget {
  margin-bottom: 50px;

  .screen-reader-text {
    display: none;
  }

  a {
    color: $color-gray-darker;

    &:hover {
      color: $color-yellow;
    }
  }

  .menu,
  .menu-item {
    flex-direction: column;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .sub-menu {
    padding-left: 20px;
  }

  &.widget_rss {
    li {
      margin-bottom: 10px;
    }
  }

  &.widget_search {
    input {
      color: $color-gray-darker;
      border: solid 1px rgba(138, 138, 141, 0.5);
      line-height: 24px;
      height: 24px;
      padding: 5px;
      display: inline-block;
      vertical-align: middle;
      transition: border-color 0.3s linear;
      border-radius: 15px;

      &:hover,
      &:focus {
        border: 1px solid $color-yellow;
        box-shadow: none !important;
        outline: none;
      }

      &[type=submit] {
        @extend .button;
        padding: 10px 30px;
        display: inline-block;
        background-color: $color-yellow;
        color: $color-white;
        border: 2px solid transparent;

        &:hover {
          border: 2px solid $color-yellow;
          background-color: $color-white;
          color: $color-yellow;
        }
      }
    }
  }
}

.similar-post {
  border-bottom: 1px solid $color-gray-open;
  margin-top: 24px;
}
.similar-post-inner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  .similar-post-thumbnail {
    flex: 0 0 auto;
    max-width: 40%;
    margin: 0 0 14px 10px;
  }

  img {
    width: auto;
    max-width: 100%;
    height: auto;
  }
}

.custom-html-content {
  padding: 10px 0;
  overflow: hidden;

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  hr {
    margin: 10px 0;
    border: none;
    border-bottom: 1px solid $color-gray-open;
  }

  .alignright {
    float: right;
    margin: 5px 0 5px 10px;
  }

  .alignleft {
    float: left;
    margin: 5px 10px 5px 0;
  }

  .aligncenter {
    margin: 5px auto;
  }

  .alignright,
  .alignleft {
    @media (max-width: 639px) {
      float: none;
      margin: 5px 0;
    }
  }

  .wp-caption-text {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  }
}
