.slideSwitch {
  @extend .flex;
  @extend .flex--stretch;
  height: 50px;
  background-color: $color-gray-lighter;
  border-radius: 25px;
  border: 2px solid $color-gray-lighter;
}

.slideSwitch__elem {
  @extend .flex;
  @extend .flex--center;
  @extend .type--500;
  padding: 0 15px;
  display: block;
  width: 100%;
  border-radius: 25px;
  white-space: nowrap;
  color: $color-gray-dark;
  cursor: pointer;
  transition: background-color $animation-timing $animation-default-bezier-curve;

  @include respond-to(md) {
    padding: 0 50px;
  }

  &.tabs__button--active,
  &.active {
    background-color: $color-white;
  }
}
