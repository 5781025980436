/**
 * Utility: Ratio-box
 * @author: Konstantinos Kataras
 *
 */

// Ratios
$ratios: ((1,1), (2,1), (1,2), (5,2), (5,3), (3,2), (2,3), (4,3), (7,6), (16,9)) !default;

.ratioBox {
  display: block;
  position: relative;
  overflow: hidden;

  @each $ratio in $ratios {
    $padding: nth($ratio, 2) / nth($ratio, 1) * 100%;

    &--#{nth($ratio, 1)}-#{nth($ratio, 2)} {
      padding-top: $padding;
    }

    &--md-down-#{nth($ratio, 1)}-#{nth($ratio, 2)} {
      @include respond-up-to(md) {
        padding-top: $padding;
      }
    }
  }


  &--stacked {
    &.ratioBox--3-2 {
      padding-top: calc(74.9% - #{$separator-sm / 2});
    }
  }

  .ratioBox__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &--center-background {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &--bellow {
      opacity: 0;
      transition: opacity $animation-timing linear;
    }

    &--above {
      opacity: 1;
      transition: opacity $animation-timing linear;
    }

    &--overflow {
      overflow: visible;
    }
  }

  &--full-height {
    min-height: 100%;
  }

  &--overflow {
    overflow: visible;
  }

  &:hover {
    .ratioBox__content {
      &--bellow {
        opacity: 1;
      }

      &--above {
        opacity: 0;
      }
    }
  }
}
