.lazyload-icon {
  position: relative;

  &:after {
    display: block;
    content: ' ';
    position: absolute;
    top: 50%;
    width: 40px;
    height: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-image: url('../assets/images/loader.svg');
    background-size: 100%;
  }

  &.lazyloaded:after {
    display:none;
  }
}

.lazyload-container {
  position: relative;

  img {
    & + span {
      display: block;
      position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url('../assets/images/loader.svg');
      background-size: 100%;
    }

    &.lazyloaded + span {
      display: none;
    }
  }
}
