.popup__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99991;
  display: none;
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 98%;
  margin: 0 auto;
  z-index: 99992;
  display: none;
  align-items: center;
}

.popup__inner {
  position: relative;
  max-height: 95%;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  border-radius: 6px;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 0 25px -2px rgba(0, 0, 0, 0.4);

  &--has-image {
    * {
      color: #fff !important;
    }

    input:not([type="checkbox"]),
    select,
    textarea {
      border-color: rgba(255, 255, 255, 1);
      color: $color-white;

      @include style-placeholder {
        color: $color-white !important;
        opacity: 0.8;
      }
    }

    .popup__content {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .gfield .validation_message {
    opacity: 1;
    color: #e30d28 !important;
    margin-top: 5px;
    font-size: 14px;
  }

  .gform_confirmation_message {
    text-align: center;
  }
}

.popup__content {
  padding: 20px 35px;
  position: relative;
  z-index: 2;
}

.popup__inner-text {
  padding: 0 0 30px 0;

  hr {
    margin: 10px 0;
    display: inline-block;
    width: 60px;
    height: 6px;
    background-color: #eda900;
    border: none;
  }
}

.popup__close {
  position: absolute;
  z-index: 3;
  width: 30px;
  height: 30px;
  top: 0;
  right: 0;
  overflow: hidden;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 4px;
}

.project-popup__download {
  padding: 20px 0;
  text-align: center;

  img {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: top;
  }

  a {
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
  }
}
