.thumbnail {
  display: block;
  width: 48px;
  min-width: 48px;
  min-height: 48px;
  height: 48px;
  overflow: hidden;

  &--rounded {
    border-radius: 50%;
  }
}

.thumbnail__text {
  @extend .type;
  @extend .type--button;

  line-height: 1.33;
  letter-spacing: -0.4px;
}