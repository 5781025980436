/**
 * Overlay Utility - This utility helps you add an overlay on hover with a text taken from the data-overlay attribute.
 *
 * @author: Konstantinos Kataras.
 */

$color-white: '#FFFFFF'!default;
$layer-background-color: rgba(0, 0, 0 , 0.5)!default;
$animation-timing: 0.2s !default;

.overlay {
  &:before {
    content: attr(data-overlay);
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $layer-background-color;
    pointer-events: none;
    color: $color-white;
    opacity: 0;
    transition: opacity $animation-timing ease-in;

    @extend .flex;
    @extend .flex--center;

    .overlay__hoverParent:hover & {
      opacity: 1;
    }
  }
}

.overlay__text {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  color: $color-white;
  position: absolute;
  opacity: 0;
  transition: opacity $animation-timing ease-in;

  @extend .flex;
  @extend .flex--column;
  @extend .flex--horizontal-center;
  @extend .flex--vertical-center;

  .overlay__hoverParent:hover & {
    opacity: 1;
  }
}

.overlay__hoverParent {
  // The parent element on which the hover event will be triggered; should have at least position relative.
}
