.link {
  cursor: pointer;

  &--yellow {
    @extend .type--yellow;
  }

  &--opaque {
    transition: opacity $animation-timing $animation-default-bezier-curve;

    &:hover {
      opacity: 0.8;
    }
  }

  &--hover-black {
    transition: color $animation-timing $animation-default-bezier-curve;

    &:hover {
      color: $color-black;
    }
  }

  &--hover-white {
    transition: color $animation-timing $animation-default-bezier-curve;

    &:hover {
      color: $color-white;
    }
  }

  &--hover-background-white {
    &:hover {
      @extend .section--white;
    }
  }

  &--block {
    display: block;
  }

  &--underlined-yellow {
    position: relative;
    transition: color $animation-timing $animation-default-bezier-curve;

    &:before {
      content: '';
      position: absolute;
      display: block;
      width: 100%;
      top: calc(100% - 1px);
      left: 0;
      border-bottom: 1px solid $color-yellow;
    }

    &:hover {
      color: $color-yellow;
    }
  }
}
