.splitLayout {
  @extend .flex;
  @extend .flex--stretch;

  position: relative;
  flex-direction: column;

  @include respond-to(md) {
    flex-direction: row;
  }

  &--remain-stacked-tablet {
    @include respond-to(sm) {
      flex-direction: column;
    }

    @include respond-to(md) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  &--reversed {
    @include respond-to(sm) {
      flex-direction: row-reverse;
    }
  }

  &--non-stretch {
    align-items: flex-start!important;
  }
}

.splitLayout__part {
  width: 100%;

  @include respond-to(md) {
    width: 50%;
  }

  &--full-height {
    min-height: calc(100vh - #{$header-height-sm-up});
  }

  &--contained {
    @extend .section;

    .splitLayout--reversed & {
      padding-left: 0!important;
    }
  }

  &--half-height-md-down {
    @include respond-up-to(md) {
      min-height: 50vh;
    }
  }

  &--last-md-down {
    padding-bottom: 19px;

    @include respond-to(md) {
      padding-bottom: 32px;
    }

    @include respond-to(lg) {
      padding-bottom: 60px;
    }

    @include respond-up-to(md) {
      order: 2;
    }
  }
}
