.filter {
  position: relative;
  @extend .type--p1;

  &--full-width-list {
    position: static;
  }
}

.filter__close {
  background-color: $color-gray-light;
  padding: 5px 20px;

  @include respond-to(sm) {
    padding: 10px 20px !important;
  }

  a {
    @extend .flex;
    @extend .flex--split;
    @extend .flex--stretch;
    @extend .flex--vertical-center;

    .icon {
      position: relative;
      right: -2px;
    }
  }
}

.filter__list {
  transition: background-color $animation-timing $animation-default-bezier-curve;

  &--last-filter-lg-up {
    @include respond-to(lg) {
      .filter__container:last-child {
        display: none!important;
      }
    }
  }

  &--hidden {
    display: none !important;
  }

  &--filtered {
    background-color: rgba(0, 0, 0, 0.4);
  }

  &--mobile {
    @include respond-up-to(sm) {
      transform: translateY(-100%);
      display: block !important;
      opacity: 0;
      position: fixed;
      width: 100%;
      top: $header-height-sm;
      left: 0;
      z-index: 9998;
      background: $color-gray-darker;
      height: calc(100vh - #{$header-height-sm});
      overflow-y: auto;
      transition: max-height 0.45s $animation-transition-drop-down-curve opacity $animation-timing $animation-default-bezier-curve, transform $animation-timing $animation-default-bezier-curve;

      &.open {
        transform: translateY(0);
        opacity: 1;
      }

      @include respond-to(sm) {
        height: calc(100vh - #{$header-height-sm-up});
        top: $header-height-sm-up;
      }

      .admin-bar & {
        height: calc(100vh - #{$header-height-sm} - 46px);

        @include respond-to(sm) {
          height: calc(100vh - #{$header-height-sm-up} - 46px);
        }

        @include respond-to-custom(783px) {
          height: calc(100vh - #{$header-height-sm-up} - 32px);
        }
      }

      .filter__container {
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .filter__wrapper {
        position: static;
        left: 0!important;
        top: 0!important;
        padding: 0;
        max-height: 0;
        transition: max-height 0.45 $animation-transition-drop-down-curve !important;
        opacity: 1!important;
      }

      .filter__title {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.67;
        padding: 5px 20px !important;
        color: $color-white;
        display: flex!important;
        justify-content: space-between;

        &--indicator {
          padding-top: 0 !important;
        }

        @include respond-to(sm) {
          font-size: 24px;
          line-height: 1.17;
          letter-spacing: -0.8px;
          padding: 10px 20px !important;
        }

        &:after {
          position: relative;
          width: 0;
          right: 8px;
          top: 5px;
        }
      }

      .filter {
        position: static!important;
        display: block!important;
        width: 100%;
        border-bottom: 1px solid $color-gray-darkest!important;

        &.open {
          .filter__wrapper {
            transition: max-height 0.6s $animation-inverse-bezier-curve !important;
            max-height: 100vh;
          }
        }
      }

      .filter__option {
        width: 100%!important;
        margin-bottom: 0;

        &:not(:last-child) {
          .filter__action {
            border-bottom: 1px solid $color-gray-light!important;
          }
        }
      }

      .filter__action {
        height: auto!important;
        text-align: left !important;
        padding: 5px 20px !important;
        display: block!important;

        @include respond-to(sm) {
          padding: 10px 20px !important;
        }
      }
    }
  }
}

.filter__container {
  @extend .flex;
  @extend .flex--center;
}

.filter__title {
  position: relative;
  padding: 0 30px 0 15px;
  z-index: 2;
  @extend .type--500;

  &--indicator {
    display: none;
    font-size: 14px!important;

    .filter__list--mobile & {
      display: block;
    }

    &:after {
      display: none !important;
    }
  }

  .filter__list--filtered & {
    color: $color-white;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    border: 5px solid transparent;
    border-top-color: $color-black;
    top: 50%;
    right: 10px;
    transform: translateY(-20%);

    .filter__list--filtered & {
      border-top-color: $color-white;
    }
  }

  .filter:not(.filter--full-width-list):hover &,
  .filter.filter--full-width-list.open & {
    @include respond-to(sm) {
      &:after {
        border-top-color: transparent;
        border-bottom-color: $color-black;
        transform: translateY(-80%);

        .filter__list--filtered & {
          border-bottom-color: $color-white;
        }
      }
    }
  }

  .filter.open & {
    @include respond-up-to(sm) {
      &:after {
        border-top-color: transparent !important;
        border-bottom-color: $color-black !important;
        transform: translateY(-80%) !important;

        .filter__list--filtered & {
          border-bottom-color: $color-white!important;
        }
      }
    }
  }
}

.filter__wrapper {
  transition: transform $animation-timing $animation-default-bezier-curve, opacity $animation-timing $animation-default-bezier-curve;
  transform-origin: 0 0;
  transform: scaleY(0);
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 10;
  right: 0;
  top: 99%;
  box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.05);

  .filter--non-opening-md-up & {
    @include respond-to(sm) {
      display: none!important;
    }
  }

  &--static {
    .filter:hover &,
    .filter.open & {
      @extend .transition-scale-down;
      transition-duration: 0.45s;
      opacity: 1;
    }
  }

  .filter--full-width-list & {
    @extend .container;
    left: -$container-padding;

    .filter:hover &,
    .filter.open & {
      pointer-events: auto;
    }

    @include respond-to(sm) {
      transition: transform 0.8s $animation-default-bezier-curve, opacity 0.8s $animation-default-bezier-curve;
      transform: translateY(-40px);
      width: 100vw;
      right: auto;
      max-width: none;
      top: 100%;
      background-color: $color-white;
      left: -$container-padding-md;

      &--static {
        transition: max-height 0.2s $animation-transition-drop-down-curve, opacity 0.8s $animation-default-bezier-curve;
        max-height: 0;
        position: static;
        transform: translateY(0);
        opacity: 0;
      }
    }

    @include respond-to(lg) {
      left: -$container-padding-lg;
    }
  }

  @include respond-to(sm) {
    .filter:hover & {
      transform: scaleY(1);
      opacity: 1;
    }

    .filter.filter--full-width-list:hover & {
      transform: translateY(0);
      opacity: 1;
      pointer-events: auto;
      z-index: 1;
    }
  }

  @include respond-up-to(sm) {
    .filter.open & {
      transform: scaleY(1);
      opacity: 1;
    }

    .filter.filter--full-width-list.open & {
      transform: translateY(0);
      opacity: 1;
      pointer-events: auto;
      z-index: 1;
    }
  }
}

.filter__options {
  border: 1px solid $gray-lighter;

  .filter--full-width-list & {
    @extend .row;
    border: none;
  }
}

.filter__option {
  position: relative;
  min-width: 220px;

  .filter--full-width-list & {
    @extend .col-xs-2;
    @extend .space--outer-bottom-sm;
    border-bottom: 0 !important;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $gray-lighter;
  }
}

.filter__action {
  @extend .type--p1;
  @extend .type--500;
  display: block;
  position: relative;
  padding: 12px 20px 12px 60px;
  color: $color-gray-dark;
  text-align: right;
  background-color: $color-white;
  transition: color $animation-timing $animation-default-bezier-curve;

  .filter--full-width-list & {
    @extend .flex;
    @extend .flex--center;
    @extend .type--h3B;
    transition: color $animation-timing $animation-default-bezier-curve, background-color $animation-timing $animation-default-bezier-curve;
    background-color: $color-gray-lighter;
    height: 150px;
    text-align: center;
    padding: 0;

    &:hover,
    &.active {
      color: $color-white;
      background-color: $color-yellow;
    }
  }

  &:hover,
  &.active {
    color: $color-black;
  }
}

.filter__indicators {
  @extend .transition-scale-down;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.8s;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include respond-to(sm) {
    flex-direction: row;
  }
}

.filter__indicators-button {
  padding-bottom: 20px;

  @include respond-to(sm) {
    padding-bottom: 0;
  }
}

.filter__indicator {
  @extend .type;
  @extend .type--white;
  @extend .type--p1;
  @extend .type--600;
  margin-right: 15px;
  padding: 20px 0;
}

[data-filter-element] {
  visibility: hidden;
}
