.thumbnailSlider {
  @extend .flex;
  @extend .flex--stretch;
  @extend .flex--split;
  height: 64px;
  position: relative;

  .swiper-slide {
    width: auto!important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: static;
    transform: translate(0, 0);
    display: block!important;
    width: 32px;
    height: 100%;
  }

  .swiper-container {
    margin: 0 20px;
  }
}

.thumbnailSlider__thumbnail {
  width: 100px;
  height: 64px;
}
