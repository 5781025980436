.validation_error {
  display: none;
}

.validation_message {
  margin-bottom: 0;
}

.gfield_label {
  display: none;
  @extend .type--gray-dark;
}

.gform_fields {
  @extend .row;
  @extend .flex;
  @extend .flex--wrap;
  @extend .flex--stretch;
}

.gform_confirmation_message {
  @extend .type--white;
}

.gform_footer {
  position: relative;

  .form--subscription & {
    position: absolute;
    display: inline-block;
    right: 0;
    bottom: auto;
    top: 0;
    width: 32px;
    height: 32px;
    //overflow: hidden;
    background-color: transparent;
    border: 2px solid $color-yellow;
    border-radius: 50%;
    color: $color-yellow;

    input {
      width: 100%;
      height: 100%;
      padding: 0;
      font-size: 0;
      background-color: transparent;
      position: absolute;
      left: 0;
      top: 0;
    }

    @extend .flex;
    @extend .flex--vertical-center;
    @extend .flex--horizontal-center;
    @extend .icon;
    @extend .icon--arrow-right;
  }

  .form--revealing & {
    position: absolute;
    display: inline-block;
    right: 0;
    bottom: 15px;

    @include respond-up-to(sm) {
      position: static;
      width: 100%;

      input {
        width: 100%;
        display: block!important;
        max-width: 480px;
      }
    }
  }

  .form--revealing.form--revealed & {
    position: static;
  }

  input[type="submit"] {
    @extend .type--markpro;
    -webkit-appearance: none;
    padding: 24px 40px;
    text-align: center;
    display: inline-block !important;
  }


  input[type="submit"] {
    .form:not(.form--subscription) & {
      @extend .button;
      @extend .button--secondary;
      @extend .type--button;
      line-height: 1.7!important;

      @include respond-up-to(sm) {
        width: 100%;
        display: block !important;
        margin-bottom: 30px;
      }
    }
  }

  .form--white & {
    input[type="submit"] {
      @extend .button--yellow;
    }
  }
}

.form--revealing {
  .ginput_container_textarea {
    margin-bottom: 30px;
  }
}

.form--subscription {
  .gfield {
    margin-bottom: 0;

    input {
      color: $color-white;
    }
  }
}

.gfield_radio {
  @extend .flex;
  @extend .flex--no-wrap;
  @extend .flex--vertical-center;

  > li {
    @extend .flex;
    @extend .flex--no-wrap;
    @extend .flex--vertical-center;
  }

  label {
    cursor: pointer;
    padding: 0 10px;
  }
}

.gfield_checkbox {
  > li {
    @extend .flex;
    @extend .flex--no-wrap;
    @extend .flex--vertical-center;
  }

  label {
    cursor: pointer;
    padding: 0 10px;
  }
}

.gfield_description {
  @extend .type;
  @extend .type--p1;
  @extend .margin-bottom--content-tight;

  min-height: 32px;
  display: flex;
  align-items: center;
}

.ginput_container_select {
  @extend .icon--arrow-left;
  position: relative;
  overflow: hidden;

  select {
    width: calc(100% + 18px) !important;
    padding-right: 18px !important;
  }

  &:before {
    font-family: iconfont;
    display: block;
    position: absolute;
    right: 0;
    color: $color-yellow;
    font-size: 25px;
    font-weight: normal;
    transform: rotate(-90deg);
    top: 5px;
    pointer-events: none;

    .form--white & {
      color: $color-white;
    }
  }
}

.gfield_html {
  width: 100%;
  margin-bottom: 0!important;
}

.gform_ajax_spinner {
  margin-left: 10px;
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  // display: none!important;

  // .section--yellow & {
  //   background-color: #fff !important;
  // }
  .contact-yellow & {
    padding: 2px;
    background-color: #fff !important;
    border-radius: 50%;
  }

  .form--subscription & {
    position: absolute;
    right: 35px;
  }
}
