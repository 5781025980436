/**
 * Utility: Hide
 * @author Konstantinos Kataras
 *
 */

.hide {
  display: none;

  &--xxs {
    @media (max-width: #{$breakpoint-xs-min - 1px}) {
      display: none !important;
    }
  }

  &--xs {
    @media (min-width: #{$breakpoint-xs-min}) and (max-width: #{$breakpoint-xs-max}) {
      display: none !important;
    }
  }

  &--sm {
    @media (min-width: #{$breakpoint-sm-min}) and (max-width: #{$breakpoint-sm-max}) {
      display: none !important;
    }
  }

  &--md {
    @media (min-width: #{$breakpoint-md-min}) and (max-width: #{$breakpoint-md-max}) {
      display: none !important;
    }
  }

  &--lg {
    @media (min-width: #{$breakpoint-lg-min}) {
      display: none !important;
    }
  }

  &--xs-up {
    @media (min-width: #{$breakpoint-xs-min}) {
      display: none !important;
    }
  }

  &--sm-up {
    @media (min-width: #{$breakpoint-sm-min}) {
      display: none !important;
    }
  }

  &--md-up {
    @media (min-width: #{$breakpoint-md-min}) {
      display: none !important;
    }
  }

  &--lg-up {
    @media (min-width: #{$breakpoint-lg-min}) {
      display: none !important;
    }
  }

  &--xs-down {
    @media (max-width: #{$breakpoint-xs-min - 1px}) {
      display: none !important;
    }
  }

  &--sm-down {
    @media (max-width: #{$breakpoint-xs-max}) {
      display: none !important;
    }
  }

  &--md-down {
    @media (max-width: #{$breakpoint-sm-max}) {
      display: none !important;
    }
  }

  &--lg-down {
    @media (max-width: #{$breakpoint-md-max}) {
      display: none !important;
    }
  }
}

.hide-grid-parent {
  &:last-child {
    .hide-grid-element {
      display: none;
    }
  }

  @include respond-to-range($breakpoint-sm, $breakpoint-sm-max) {
    &:nth-child(2n+1):nth-last-child(-n+2),
    &:nth-child(2n+1):nth-last-child(-n+2) ~ .hide-grid-parent {
      .hide-grid-element {
        display: none;
      }
    }
  }

  @include respond-to-range($breakpoint-md, $breakpoint-md-max) {
    &:nth-child(3n+1):nth-last-child(-n+3),
    &:nth-child(3n+1):nth-last-child(-n+3) ~ .hide-grid-parent {
      .hide-grid-element {
        display: none;
      }
    }
  }

  @include respond-to(lg) {
    &:nth-child(4n+1):nth-last-child(-n+4),
    &:nth-child(4n+1):nth-last-child(-n+4) ~ .hide-grid-parent {
      .hide-grid-element {
        display: none;
      }
    }
  }
}
