.scale {
  &--small-text {
    font-size: 0.35em;

    @include respond-to(xs) {
      font-size: 0.5em;
    }

    @include respond-to(sm) {
      font-size: 1em;
    }
  }
}
