// Body

body {
  color: $default-color;
  padding-top: $header-height-sm;
  font-family: $default-font-family;
  background-color: $color-white;
  -webkit-font-smoothing: antialiased;
  width: 100%;

  @include respond-to(sm) {
    padding-top: $header-height-sm-up;
  }

  .admin-bar & {
    padding-top: $header-height-sm + 46px;

    @include respond-to(sm) {
      padding-top: $header-height-sm-up + 46px;
    }

    @include respond-to-custom(783px) {
      padding-top: $header-height-sm-up + 32px;
    }
  }
}
