$deco-padding-xs: 18px;
$deco-padding-sm: 32px;

$deco-width-xs: 44px;
$deco-width-sm: 60px;

$deco-height-xs: 4px;
$deco-height-sm: 8px;

.deco {
  position: relative;
  display: block;

  &--underline {
    padding-bottom: $deco-padding-xs;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: $deco-width-xs;
      height: $deco-height-xs;
      background-color: $color-yellow;

      @include respond-to(sm) {
        width: $deco-width-sm;
        height: $deco-height-sm;
      }
    }

    @include respond-to(sm) {
      padding-bottom: $deco-padding-xs;
    }
  }

  @each $key, $color in $colors {
    &--#{$key} {
      &:after {
        background-color: $color;
      }
    }
  }

  &--quotes {
    &:before {
      content: '“';

      @include respond-to(md) {
        position: absolute;
        //right: calc(100% + 5px);
        left: -18px;
      }
    }

    &:after {
      content: '“';
    }
  }

  &--reveal {
    @extend .position--stressed;
    position: absolute!important;
    transition: transform 1.2s $animation-default-bezier-curve;
    transform: translateY(0);

    &:after {
      top: -#{$deco-height-xs};
      bottom: auto;

      @include respond-to(sm) {
        top: -#{$deco-height-sm};
      }
    }

    .start-animate &,
    &.start-animate {
      transform: translateY(100%);

      @include respond-to(sm) {
        transform: translateY(100%);
      }
    }
  }

  &--overlay {
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(#000, 0.09);
    }
  }

  &--image-opaque {
    opacity: 0.6;
  }

  &--circleBox {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    background-size: 150px auto;
    transition: min-width $animation-timing $animation-default-bezier-curve, border-radius $animation-timing $animation-default-bezier-curve, background $animation-timing $animation-default-bezier-curve;

    @include respond-to(sm) {
      width: 150px;
      height: 150px;
      min-width: 150px;
      min-height: 150px;
    }

    [data-scale-modal="action"]:hover & {
      @include respond-to(sm) {
        background-size: 170px auto!important;
      }
    }

    .triggered & {
      min-width: 100%;
      border-radius: 0;
      background-size: 150px auto!important;
    }
  }

  &--fill-background-left {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      background-color: $color-gray-lighter;
      width: 100vw;
      left: -7px;
      z-index: -1;

      @include respond-to(md) {
        left: 0;
        width: calc(100% + #{$container-padding});
      }

      @include respond-to(lg) {
        left: 0;
        width: calc(100% + #{$container-padding-lg - ($grid-gutter-width / 2)});
      }

      @include respond-to-custom($max-width-lg) {
        width: calc(100% + 50vw - #{($max-width-lg / 2) - ($container-padding-lg) + $grid-gutter-width /2});
      }
    }
  }

  &--fill-background-right {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      background-color: $color-gray-lighter;
      width: 100vw;
      right: -7px;
      z-index: -1;

      @include respond-to(md) {
        right: 0;
        width: calc(100% + #{$container-padding});
      }

      @include respond-to(lg) {
        right: 0;
        width: calc(100% + #{$container-padding-lg - ($grid-gutter-width / 2)});
      }

      @include respond-to-custom($max-width-lg) {
        width: calc(100% + 50vw - #{($max-width-lg / 2) - ($container-padding-lg) + $grid-gutter-width /2});
      }
    }
  }

  &--triangle {
    border: 10px solid transparent;
    width: 0;
    height: 0;
  }

  &--padding-compatible {
    position: absolute;
    top: 0;
    left: 13px;

    @include respond-to(md) {
      left: 53px;
    }
  }

  &--white-border-bottom {
    border-bottom: 1px solid $color-white;
  }

  &--bottom {
    border-top-color: $color-yellow;
  }

  @each $key, $color in $colors {
    &--reveal-#{$key} {
      background-color: $color;
    }
  }
}

.deco-underline-padding {
  padding-bottom: $deco-padding-xs;
  display: block;

  @include respond-to(sm) {
    padding-bottom: $deco-padding-sm;
  }
}
