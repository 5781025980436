.c-modal-box {
  @include respond-to(lg) {
    position: relative;
    display: flex;
    height: 330px;
    overflow: hidden;
    align-items: stretch;
    margin-left: auto;
    margin-right: auto;
    cursor: default;
  }

  &__overflow {
    @include respond-to(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-height: none;
      padding: 16px 7%;
    }
  }

  &__overflow-inner {
    @include respond-to(lg) {
      padding: 0;
      max-height: none;
      height: auto;
    }
  }

  .ratioBox,
  .ratioBox__content {
    height: 100%;
    transform: scale(1);
  }
}

.c-modal-box--icon {
  @extend .c-modal-box;
  max-width: 840px;

  .c-modal-box__image {
    @include respond-to(lg) {
      height: 100%;
    }
  }
}


.c-modal-box--photo {
  @extend .c-modal-box;

  @include respond-to(lg) {
    width: 980px;
    height: 486px;

    .c-modal-box__overflow {
      padding: 0 30px 0 60px;
    }

    .c-modal-box__overflow-inner {
      padding-right: 30px;
      margin: 40px 0 40px;

      &:after {
        content: '';
        display: block;
        width: 60px;
        height: 8px;
        margin: 30px auto 0;
        background: $color-yellow;
      }
    }

    .c-modal-box__image {
      width: 300px;
      height: 486px;
    }
  }
}
