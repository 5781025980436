.project {
  @media (max-width: 767px) {
    &--order-1 {
      order: 1;
    }

    &--order-2 {
      order: 2;
    }
  }
}

@media (max-width: 767px) {
  .margin-mobile {
    margin-top: 24px;
  }
}

.plan-btn-container {
  display: none;
}
